/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Add typography inheritance in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  background-color: transparent; /* 1 */
  border: 1px solid WindowFrame; /* 1 */
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  padding: 0.25em 0.375em; /* 1 */
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: no-repeat right center / 1em;
  border-radius: 0;
  padding-right: 1em;
}

/**
 * Change the inconsistent appearance in all browsers (opinionated).
 */

select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E");
}

/**
 * Change the inconsistent appearance in IE (opinionated).
 */

::-ms-expand {
  display: none;
}

/**
 * Correct the inconsistent appearance in IE (opinionated).
 */

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}
